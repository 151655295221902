<template>
  <v-hover v-slot="{ hover }">
    <v-row fluid no-gutters class="d-flex flex-column flex-sm-row">
      <v-col cols="sm-5 pr-sm-12 mb-4 mb-sm-0">
        <iframe class="d-block" :src='item.playback_url' frameborder='0' :width='embed_width' :height='embed_height' scrolling='no' allowfullscreen='true' :style='styObj'></iframe>
      </v-col>
      <v-col cols="sm-7 pl-sm-12 mb-12 mb-sm-0">
        <div class="text-h6 text-sm-h5 grey--text text--darken-4 font-weight-bold cs-title">
          <a :href="item.link_url" :style="{color: hover ? '#01BDCB !important':'#212121'}">{{ item.title }}</a>
          <a
            v-if="!!item.download_url"
            :href="item.download_url"
            target="_blank"
          >
            <v-avatar
              :size="25"
              class="cs-download"
            >
              <v-img
                width="25"
                max-width="25"
                height="25"
                :src="require('@/assets/download.svg')"
                class="flex-grow-0 d-inline"
              />
            </v-avatar>
          </a>
        </div>
        <div v-if="item.name" class="d-flex align-center grey--text text--darken-4 mt-4">
          <div class="pr-2">
            <v-avatar size="44"><img :src="item.avatar" :alt="item.name"></v-avatar>
          </div>
          <div class="flex-grow-1" style="width: 0;">
            <div v-if="item.name" class="pr-2 text-no-wrap font-weight-bold">{{ item.name }}</div>
            <div v-if="item.career" class="text-truncate cs-color">{{ item.career }}</div>
          </div>
        </div>
        <div v-if="item.content" class="text-body-1 font-weight-bold mt-4">话题简介：</div>
        <div v-if="item.content" class="mt-2 cs-color">{{ item.content }}</div>
        <div v-if="item.career" class="text-body-1 font-weight-bold mt-4">讲师简介：</div>
        <div v-if="item.career" class="mt-2 cs-color">{{ item.intro ? item.intro : item.career }}</div>
      </v-col>
    </v-row>
  </v-hover>
</template>
<script>
const PLAYER_WIDTH = 452
const PLAYER_HEIGHT = 254.25
import Tools from '@/mixins/Tools.js'
export default {
  name: 'PlaybackCard',
  mixins: [Tools],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      embed_width: PLAYER_WIDTH,
      embed_height: PLAYER_HEIGHT
    }
  },
  computed: {
    styObj() {
      return {
        display: 'block',
        width: this.embed_width + 'px',
        height: this.embed_height + 'px'
      }
    }
  },
  mounted() {
    this.handleMobile(this.is_mobile)
  },
  watch: {
    is_mobile(newV) {
      this.handleMobile(newV)
    }
  },
  methods: {
    handleMobile(bool) {
      if (bool) {
        this.embed_width = window.screen.width - 24
        this.embed_height = (window.screen.width - 24) * 9/16
      } else {
        this.embed_width = PLAYER_WIDTH
        this.embed_height = PLAYER_HEIGHT
      }
      this.player_key = `player-key-${new Date().getTime() }`
    },
    // 下载ppt
    handleDownload(href) {
      document.location.href = href
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-title
  line-height: 1.1
.cs-color
  color: #666 !important
.cs-download
  cursor: pointer
  transition: all .3s
  &:hover
    transform: scale(1.15, 1.15)
</style>
<style>
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
  color: #000;
}
</style>